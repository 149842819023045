
/* You can add global styles to this file, and also import other style files */
/* START: Library Functions, Helpers & Color Variables */
@use "sass:math";
@function str-explode ($string, $delimiter: '') {
 @if type-of($string) != 'string' {
   @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
 }
 @else if type-of($delimiter) != 'string' {
   @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
 }
 
 $result: ();
 $running: true;
 
 @if str-length($delimiter) == 0 {
   @for $i from 1 through str-length($string) {
     $result: append($result, str-slice($string, $i, $i));
   }
   
   @return $result;
 }
 
 @while $running {
   $index: str-index($string, $delimiter);
   
   @if $index != null {
     $item: str-slice($string, 1, ($index - 1));
     $result: append($result, $item);
     $string: str-slice($string, ($index + str-length($delimiter)));
   }
   @else {
     $running: false;
   }
 }
 
 @return append($result, $string);
}
@function str-replace ($string, $search, $replace: '') {
 @if type-of($string) != 'string' {
   @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
 }
 @else if type-of($search) != 'string' {
   @error 'The argument $search: `#{$search}` is of incorrect type: `#{type-of($search)}`. Type of `String` is required!';
 }
 @else if type-of($replace) != 'string' {
   @error 'The argument $search: `#{$search}` is of incorrect type: `#{type-of($search)}`. Type of `String` is required!';
 }
 
 $index: str-index($string, $search);
 
 @if $index {
   @return str-slice($string, 1, $index - 1) + $replace +
     str-replace(
       str-slice($string, $index + str-length($search)),
       $search, $replace
     );
 }
 
 @return $string;
}
@function str-capitalize ($string) {
 @if type-of($string) != 'string' {
   @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
 }
 
 @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}
@function strip-unit ($number) {
 @if type-of($number) != 'number' {
   @error 'The argument $number: `#{$number}` is of incorrect type: `#{type-of($number)}`. Type of `Number` is required!';
 }
 @else if unitless($number) {
   @warn 'The argument $number: `#{$number}` has no unit. Unit is required!';
 }
 
 @return $number / ($number * 0 + 1);
}
@function fix-precision ($number, $digits: 6) {
 $n: 1;
 
 @for $i from 1 through $digits {
   $n: $n * 10;
 }
 
 @return math.div(floor($number * $n), $n);
}
@function ratio($w, $h) {
 @return ($w/$h) + 1px;
}
$config: () !default;
@function deep-map-check ($map, $keys...) {
 @if type-of($map) != 'map' {
   @error '1: The argument $map: `#{$map}` is of incorrect type: `#{type-of($map)}`. Type of `Map` is required!';
 }

 @each $key in $keys {
   @if not map-has-key($map, $key) {
     @return false;
   }

   $map: map-get($map, $key);
 }

 @return true;
}
@function deep-map-get ($map, $keys...) {
 @if type-of($map) != 'map' {
   @error '2: The argument $map: `#{$map}` is of incorrect type: `#{type-of($map)}`. Type of `Map` is required!';
 }
 @each $key in $keys {
   $map: map-get($map, $key);
 }

 @return $map;
}
@function deep-map-merge ($map, $maps...) {
 $last: nth($maps, -1);
 $deep: $last == true;
 $max: if($deep, length($maps) - 1, length($maps));

 // Loop through all maps in $maps...
 @for $i from 1 through $max {
   // Store current map
   $current: nth($maps, $i);

   // If not in deep mode, simply merge current map with map
   @if not $deep {
     $map: map-merge($map, $current);
   }
   @else {
     // If in deep mode, loop through all tuples in current map
     @each $key, $value in $current {

       // If value is a nested map and same key from map is a nested map as well
       @if type-of($value) == 'map' and type-of(map-get($map, $key)) == 'map' {
         // Recursive extend
         $value: deep-map-merge(map-get($map, $key), $value, true);
       }

       // Merge current tuple with map
       $map: map-merge($map, ($key: $value));
     }
   }
 }

 @return $map;
}
@function get ($key, $map: $config) {
 $keys: str-explode($key, '.');
 @if not deep-map-check($map, $keys...) {
   @debug $keys;
   @error '3: The argument $map: `#{$map}` doesn\'t has some of the $keys: `#{$keys}`!';
 }
 @return deep-map-get($map, $keys...);
}
$config: deep-map-merge(
   (
     'color': (
       'primary': (
           'default': #4314FF,
           'light': #4314FF,
           'medium': #4314FF,
           'mediumDark': #2B03CA,
           'mediumDarker': #2B03CA,
           'dark': #2B03CA,
           'darkest': #2B03CA,
           'white': #FFFFFF
       ),
       'secondary': (
           'default': #7E5EFF,
            'light': #CEC1FF,
            'medium': #AB96FF,
            'dark': #692BF0,
       ),
       'tertiary': (
           'default': #EF247D
       ),
        'accent': (
          'first': #F685FF
        ),
       'brand': (
           'brown': #814D39,
           'green': #97CA5E,
           'greenLight': #E1F3CE,
           'greenMedium': #84B24F,
           'orange': #FF6E35,
           'yellow': #FFBD00,
           'yellowLight': #FFEFC1,
           'yellowMedium': #E79900
       ),
       'grey': (
           'background': #F4F2F8,
           'black': #0D0C0F,
           'dark': #413E4B,
           'darker': #2A2831,
           'light': #EDECF2,
           'lighter': #F4F2F8,
           'medium': #AFABC2
       ),
       'font': (
           'body': #3A3844,
           'light': #7A768B,
           'lighter': #AFABC2,
           'medium': #7A768B,
           'headline': #2A2831,
           'dark': #2A2831
       ),
       'alerts': (
           'orange': #FF6E35,
           'blue': #87BDFF,
           'blueLight': #D9EAFF,
           'blueMedium': #6AA6F0,
           'red': #DA3030,
           'redLight': #FA3F3F,
           'green': #8ED144,
           'yellow': #FFBD00,
           'pink': #db3d97,
           'purple': #8336d6
       )
     )
   ),
   $config,
   true
);
$config: deep-map-merge(
   (
     'ui': (
       'border': (
         'weight': 2px,
         'radius': (
           'min': 6px,
           'input': 8px,
            'button': 8px,
           'tag': 8px,
            'box': 8px,
           'large': 16px,
           'max': 24px
         )
       ),
       'air': (
           'default': 24px,
           'min': 6px,
           'small': 8px,
           'medium': 12px,
           'mediumLarge': 18px,
           'large': 32px,
           'xlarge': 48px,
           'xxlarge': 64px,
           'max': 88px,
           'editorial': (
             'paragraph': 1.5em,
             'subsection': 4em,
             'section': 9em,
             'sectionMobile': 7em,
             'min': 32px,
             'max': 120px,
           )
       ),
       'responsive': (
         'smallscreen': 960px,
         'tablet': 1100px,
         'laptop': 1200px,
         'desktop': 1600px,
         'mobile': (
           'default': 660px,
           'xlarge': 780px,
           'large': 720px,
           'medium': 580px,
           'small': 480px,
           'min': 380px
         )
       ),
       'modal': (
         'default': 600px,
         'small': 480px,
         'xsmall': 320px,
         'large': 720px,
         'xlarge': 860px,
         'max': 90vw
       ),
       'font': (
         'size': (
           'body': 18px,
           'small': 14px,
           'xsmall': 12px,
           'label': 15px,
           'note': 13px,
           'min': 11px,
           'tab': 15px,
           'menu': 18px,
           'input': 18px,
           'placeholder': 16px,
           'button': (
             'default': 18px,
             'large': 21px,
             'small': 14px
           ),
           'tag': (
             'default': 14px,
             'large': 16px,
             'small': 12px
           ),
           'headline': (
             'one': 5em,
             'two': 3.25em,
             'three': 2.35em,
             'four': 1.5em,
             'five': 1.25em
           )
         )
       ),
       'icon': (
           'default': 32px,
           'small': 24px,
           'xsmall': 12px,
           'min': 10px,
           'large': 48px,
           'tool': 24px
       ),
       'button': (
           'default': 42px,
           'small': 32px,
           'xsmall': 24px,
           'large': 50px,
           'xlarge': 60px,
           'tool': 36px
       ),
       'input': (
           'default': 42px,
           'small': 32px,
           'large': 50px,
           'checkbox': 24px,
           'checkboxSmall': 18px,
           'radio': 22px,
           'radioSmall': 16px,
           'switch': 20px,
           'switchSmall': 15px
       ),
       'toolbar': (
         'default': 50px,
         'large': 70px,
         'main': 70px,
         'mobile': 60px
       ),
       'tag': (
        'default': 24px,
         'large': 32px,
         'small': 18px
       ),
       'user': (
         'thumbnail': (
           'default': 50px,
           'small': 38px,
           'xsmall': 22px,
           'large': 66px,
           'icon': 30px
         )
       )
     )
   ),
   $config,
   true
);
/* END: Library Functions, Helpers & Color Variables */

/* START: Main Framework */

@mixin responsive($min-width: null, $device: null, $max-width: null, $max-height: null) {
 @if $device {
   @if $device == 'mobile' {
     @media screen and (max-width: get('ui.responsive.mobile.default')) {
       @content;
     }
   }
   @if $device == 'mobile-large' {
     @media screen and (max-width: get('ui.responsive.mobile.large')) {
       @content;
     }
   }
   @if $device == 'mobile-medium' {
     @media screen and (max-width: get('ui.responsive.mobile.medium')) {
       @content;
     }
   }
   @if $device == 'mobile-small' {
     @media screen and (max-width: get('ui.responsive.mobile.small')) {
       @content;
     }
   }
   @if $device == 'mobile-min' {
     @media screen and (max-width: get('ui.responsive.mobile.min')) {
       @content;
     }
   }
   @if $device == 'desktop' {
     @media screen and (max-width: get('ui.responsive.desktop')) {
       @content;
     }
   }
   @if $device == 'laptop' {
     @media screen and (max-width: get('ui.responsive.laptop')) {
       @content;
     }
   }
   @if $device == 'tablet' {
     @media screen and (max-width: get('ui.responsive.tablet')) {
       @content;
     }
   }
   @if $device == 'smallscreen' {
     @media screen and (max-width: get('ui.responsive.smallscreen')) {
       @content;
     }
   }
 } @else {
   @if $min-width and $max-width {
     @media screen and (min-width: $min-width) and (max-width: $max-width) {
       @content;
     }
   } @else if $min-width {
     @media screen and (min-width: $min-width) {
       @content;
     }
   } @else if $max-width {
     @media screen and (max-width: $max-width) {
       @content;
     }
   } @else if $max-height {
     @media screen and (max-height: $max-height) {
       @content;
     }
   }
 }
}

@mixin frame($size: "default", $type: "default") {
 $s: null;

 @if $size == "default" {
   $s: get('ui.air.default');
 }
 @else if $size == 'min' {
   $s: get('ui.air.min');
 }
 @else if $size == 'small' {
   $s: get('ui.air.small');
 }
 @else if $size == 'medium' {
   $s: get('ui.air.medium');
 }
 @else if $size == 'medium-large' {
   $s: get('ui.air.mediumLarge');
 }
 @else if $size == 'large' {
   $s: get('ui.air.large');
 }
 @else if $size == 'xlarge' {
   $s: get('ui.air.xlarge');
 }
 @else if $size == 'xxlarge' {
   $s: get('ui.air.xxlarge');
 }
 @else if $size == 'max' {
   $s: get('ui.air.max');
 }

 @if $type == "default" {
   padding: $s;
 }
 @else if $type == 'horizontal' {
   padding-left: $s;
   padding-right: $s;
 }
 @else if $type == 'vertical' {
   padding-top: $s;
   padding-bottom: $s;
 }
 @else if $type == 'top' {
   padding-top: $s;
 }
 @else if $type == 'right' {
   padding-right: $s;
 }
 @else if $type == 'bottom' {
   padding-bottom: $s;
 }
 @else if $type == 'left' {
   padding-left: $s;
 }
}

@mixin margin($size: "default", $type: "default") {
 $s: null;

 @if $size == "default" {
   $s: get('ui.air.default');
 }
 @else if $size == 'min' {
   $s: get('ui.air.min');
 }
 @else if $size == 'small' {
   $s: get('ui.air.small');
 }
 @else if $size == 'medium' {
   $s: get('ui.air.medium');
 }
 @else if $size == 'medium-large' {
   $s: get('ui.air.mediumLarge');
 }
 @else if $size == 'large' {
   $s: get('ui.air.large');
 }
 @else if $size == 'xlarge' {
   $s: get('ui.air.xlarge');
 }
 @else if $size == 'xxlarge' {
   $s: get('ui.air.xxlarge');
 }
 @else if $size == 'max' {
   $s: get('ui.air.max');
 }

 @if $type == "default" {
   margin: $s;
 }
 @else if $type == 'horizontal' {
   margin-left: $s;
   margin-right: $s;
 }
 @else if $type == 'vertical' {
   margin-top: $s;
   margin-bottom: $s;
 }
 @else if $type == 'top' {
   margin-top: $s;
 }
 @else if $type == 'right' {
   margin-right: $s;
 }
 @else if $type == 'bottom' {
   margin-bottom: $s;
 }
 @else if $type == 'left' {
   margin-left: $s;
 }
}

@each $s in 12 {
  $size: math.div(100, $s);

 @for $i from 1 through $s {
   .grid-#{$i}-#{$s} {
     $width: fix-precision($size * $i);
     width: unquote($width + "%");
 }
}
}

@mixin hover($type: null) {
 @if $type == 'scale-large' {
   transform: scale(1);
   transition: transform 0.25s ease-in-out;
   
   &:hover {
     transform: scale(1.125);
     transition: transform 0.425s ease-in-out;
   }
 }
 
 @if $type == 'scale-medium' {
   transform: scale(1);
   transition: transform 0.2s ease-in-out;
   
   &:hover {
     transform: scale(1.075);
     transition: transform 0.375s ease-in-out;
   }
 }
 
 @if $type == 'scale-small' {
   transform: scale(1);
   transition: transform 0.2s ease-in-out;
   
   &:hover {
     transform: scale(1.05);
     transition: transform 0.375s ease-in-out;
   }
 }
 
 @if $type == 'tag' {
   transform: scale(1);
   transition: transform 0.2s ease-in-out;
   
   &:hover {
     transform: scale(1.075);
     transition: transform 0.375s ease-in-out;
   }
 }
}

@mixin background-image($url: null, $size: 'contain', $position: 'center', $repeat: 'no-repeat', $pseudo: 'none') {
 @if $pseudo == 'none' {
   @if $url {
     background-image: url($url);
   }
   background-repeat: unquote($repeat);
   background-position: unquote($position);
   background-size: unquote($size);
 } 
 @else if $pseudo == 'before' {
   &:before {
     @if $url {
       background-image: url($url);
     }
     background-repeat: unquote($repeat);
   background-position: unquote($position);
   background-size: unquote($size);
   }
 }
 @else if $pseudo == 'after' {
   &:after {
     @if $url {
       background-image: url($url);
     }
     background-repeat: unquote($repeat);
     background-position: unquote($position);
     background-size: unquote($size);
   }
 }
}

@mixin icon($icon: null, $weight: 'solid', $pseudo: 'before') {
 @if $pseudo == 'before' {
   &:before {
     display: inline-flex;
     align-items: center;
     justify-content: center;
     font-family: "Font Awesome 6 Pro";
     font-weight: 800;
     @if $icon {
       content: $icon;
     }
   }
 } @else {
   &:after {
     display: inline-flex;
     align-items: center;
     justify-content: center;
     font-family: "Font Awesome 6 Pro";
     font-weight: 800;
     @if $icon {
       content: $icon;
     }
   }
 }
}

@mixin scrollbar {
 overflow-y: auto;

 &::-webkit-scrollbar {
   width: 4px;
   margin: 10px;
   background-color: #E1E4F5;

 }

 &::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 #4d92ff;
   border-radius: 6px;
 }

 &::-webkit-scrollbar-thumb {
   background-color:  #4d92ff;
   outline: 1px solid transparent;
   border-radius: 6px;
 }
}

@mixin border($type: "default", $color: "default", $weight: "2px", $direction: "all", $hover: 'false') {
 $w: unquote($weight);
 $c: null;
 $ch: null;
 
 @if $color == "default" {
   $c: get('color.grey.light');
   $ch: get('color.grey.medium');
 } @else if $color == "light" {
   $c: get('color.grey.light');
   $ch: get('color.grey.medium');
 } @else {
   $c: unquote($color);
   $ch: darken($c,5%);
 }
 
 @if $type == "default" {
   @if $direction == 'all' {
     border: $w solid $c;
   }
   
   @if $direction == 'right' {
     border-right: $w solid $c;
   }
   
   @if $direction == 'bottom' {
     border-bottom: $w solid $c;
   }
   
   @if $direction == 'left' {
     border-left: $w solid $c;
   }
   
   @if $direction == 'top' {
     border-top: $w solid $c;
   }
   
   @if $direction == 'horizontal' {
     border-left: $w solid $c;
     border-right: $w solid $c;
   }
   
   @if $direction == 'vertical' {
     border-top: $w solid $c;
     border-bottom: $w solid $c;
   }
   
   @if $hover == 'true' {
     &:hover {
       border-color: $ch;
       transition: border-color 0.2s ease-out;
     }
   }
 }
 
 @if $type == "box-shadow" {
   box-shadow: inset 0 0 0 $w $c;
   
   &:hover {
     box-shadow: inset 0 0 0 $w $ch;
     transition: all 0.2s ease-out;
   }
 }
 
 @if $type == "input" {
   box-sizing: border-box;
   border: $w solid $c;
   
   &:hover {
     border-color: $ch;
     transition: all 0.2s ease-out;
   }
   
   &:focus {
     border-color: $ch;
     box-shadow: 0 0 8px 0 rgba($ch,0.35);
     transition: all 0.2s ease-out;
   }
 }
}

@mixin box($style: "default", $hover: 'false') {
 background-color: #FFF;
 border-radius: get('ui.border.radius.box');
 position: relative;
 
 @if $style == "default" {
   @include border;
   
   @if $hover == "true" {
     @include border($hover: 'true');
   }
   
   &.hover {
     &:not(.active) {
       @include border($hover: 'true');
     }
   }
   
   &.input {
     min-height: get('ui.input.default');
     padding: 4px;
   }
   
   &.active {
     border-color: get('color.primary.medium');
   }
   
   &.selected {
     border-color: get('color.alerts.green');
     
     &:after {
       display: flex;
       align-items: center;
       justify-content: center;
       font-family: "Font Awesome 6 Pro";
       content: "\f00c";
       color: get('color.alerts.green');
       position: absolute;
       bottom: get('ui.air.small');
       right: get('ui.air.small');
       font-weight: 800;
       font-size: 80%;
     }
   }
 }
 
 @if $style == "grey" {
   background-color: get('color.grey.lighter');
    border-radius: get('ui.border.radius.box');
   
   @if $hover == "true" {
     @include border($hover: 'true');
   }
   
   &.hover {
     &:not(.active) {
       @include border($hover: 'true');
     }
   }
 }
 
 @if $style == "shadow" {
   box-shadow: 0 1px 3px 0 rgba(get('color.grey.medium'),0.35);
   
    @if $hover == "true" {
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(get('color.grey.medium'),0.625),0 0 0 3px rgba(get('color.grey.medium'),0.15);
        transition: all 0.15s ease-in-out;
      }
    }
    
   &.hover {
     &:hover {
       box-shadow: 0 1px 3px 0 rgba(get('color.grey.medium'),0.5);
     }
   }
 }
 
 @if $style == "shadow-hard" {
   box-shadow: 0 3px  0 rgba(get('color.grey.medium'),0.35);
   
    &.grey {
      background-color: get('color.grey.lighter');
      
      &.hover {
        border: none;
      }
    }
    
   &.hover {
     &:hover {
       box-shadow: 0 3px 0 0 rgba(get('color.grey.medium'),0.5);
        transition: all 0.15s ease-in-out;
        
        &.grey {
          background-color: darken(get('color.grey.lighter'),2%);
          transition: all 0.15s ease-in-out;
          border: none;
        }
     }
   }
 }
 
 @if $style == "popup" {
   box-shadow: 
      0 4px 56px 0 rgba(get('color.grey.dark'),0.25),
      0 38px 40px -18px rgba(get('color.grey.dark'),0.325);
 }
}

@mixin button($size: "default", $type: "primary") {
 $w: null;
 $h: null;
 
 @if $size == "default" {
   $w: get('ui.button.default');
   $h: get('ui.button.default');
 }
 @else if $size == 'large' {
   $w: get('ui.button.large');
   $h: get('ui.button.large');
 }
 @else if $size == 'small' {
   $w: get('ui.button.small');
   $h: get('ui.button.small');
 }
 
 display: inline-flex;
 align-items: center;
 justify-content: center;
 font-family: greycliff-cf, sans-serif !important;
 min-height: $h;
 border: none;
 outline: none;
 border-radius: get('ui.border.radius.button');
 cursor: pointer;
 padding-left: 0.875em;
 padding-right: 0.875em;
 font-size: get('ui.font.size.button.default');
 line-height: 1;
 font-weight: 700;
 user-select: none;
 white-space: nowrap;
 
 .icon,
 i {
   margin-right: 0.575em;
   margin-left: -1px;
 }
 
 .icon {
   width: get('ui.icon.small');
   height: get('ui.icon.small');
   
   img {
     display: block;
     width: 100%;
     height: auto;
   }
 }
 
 i {
   &:before {
     font-weight: 800;
     color: inherit;
   }
 }
   
 @if $type == "primary" {
   background-color: get('color.primary.default');
   color: #FFF;
   box-shadow: none;
   
   i {
     color: get('color.primary.dark');
   }
   
   & > .label,
   & > .icon {
     margin-right: get('ui.air.small');
     
     &:last-child {
       margin-right: 0;
     }
   }
   
   &:hover {
     background-color: get('color.primary.dark');
     transition: background-color 0.2s ease-out;
   }
   
   &.large {
     font-size: get('ui.font.size.button.large');
     min-height: get('ui.button.large');
   }

   &.small {
     font-size: get('ui.font.size.button.small');
     min-height: get('ui.button.small');
   }
   
   &.block {
     width: 100%;
     position: relative;
     
     & > .icon {
       justify-self: flex-end;
       position: absolute;
       right: get('ui.air.medium');
     }
   }
 }
 
 @if $type == "secondary" {
   @include border($weight: "1px");
   background-color: #FFF;
   color: get('color.primary.default');
   border-color: get('color.primary.default');
   
   i {
     color: get('color.primary.default');
     margin-left: -2px;
   }
   
   &:hover {
     border-color: get('color.primary.dark');
     color: get('color.primary.dark') !important;
   }
   
   &.large {
     font-size: get('ui.font.size.button.large');
     min-height: get('ui.button.large');
   }

   &.small {
     font-size: get('ui.font.size.button.small');
     min-height: get('ui.button.small');
   }
 }
 
 @if $type == "icon" {
   @include border($weight: "1px");
   width: $h;
   height: $h;
   background-color: #FFF;
   color: get('color.primary.default');
   border-color: get('color.grey.medium');
   padding-left: 0;
   padding-right: 0;
   
   i {
     color: get('color.primary.default');
     margin: 0;
   }
   
   &:hover {
     border-color: get('color.primary.dark');
     color: get('color.primary.dark');
   }
   
   &.large {
     font-size: get('ui.font.size.button.large');
     min-height: get('ui.button.large');
   }

   &.small {
     font-size: get('ui.font.size.button.small');
     min-height: get('ui.button.small');
   }
 }
 
  @if $type == "tool" {
    @include box('shadow','true');
    min-height: 38px;
    padding: 0 10px;
    background-color: #FCFCFD;
    
    .icon {
      color: get('color.primary.default');
      width: 12px;
      height: 12px;
      margin-right: 5px;
      
      img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
    
    .label {
      font-weight: 500;
      font-size: 15px;
    }
  }
  
 &.style-blank {
   border: none;
   background-color: transparent;
   box-shadow: none;
 }
 
 &.disabled {
   cursor: not-allowed;
 }
}

body {
 min-height: 100vh;
 font-family:  greycliff-cf, sans-serif;
 color: get('color.font.body');
 padding: 0;
 margin: 0;

 * {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
 }
}

h1, h2, h3, h4, h5 {
 font-weight: 700;
 line-height: 1.35;
 color: get('color.font.headline');
}

h1 {
 font-size: get('ui.font.size.headline.one');
 line-height: 1;
 letter-spacing: -0.02em;
 
 @include responsive($max-width: get('ui.responsive.mobile.default')) {
   font-size: get('ui.font.size.headline.one') * 0.85;
 }
}

h2 {
 font-size: get('ui.font.size.headline.two');
 line-height: 1.2;
 letter-spacing: -0.02em;
 
 &.capital-number {
   font-weight: 200;
   color: get('color.primary.default');
 }
 
 @include responsive($max-width: get('ui.responsive.mobile.default')) {
   font-size: get('ui.font.size.headline.two') * 0.85;
 }
}

h3 {
 font-size: get('ui.font.size.headline.three');
 line-height: 1.2;
 
 @include responsive($max-width: get('ui.responsive.mobile.default')) {
   font-size: get('ui.font.size.headline.three') * 0.95;
 }
}

h4 {
 font-size: get('ui.font.size.headline.four')
}

h5 {
 font-size: get('ui.font.size.headline.five')
}

.brand {
 display: block;
 font-size: 0;
 width:208px;
 height: 56px;
 background-image: url('/assets/home/xound-brand.svg');
 background-repeat: no-repeat;
 background-size: contain;
}

.frame {
 @include frame;

 &-min {
   @include frame("min");
 }

 &-small {
   @include frame("small");
 }

 &-medium {
   @include frame("medium");
 }

 &-large {    
   @include responsive($min-width: '660px') {
     @include frame("xlarge");
   }

   @include responsive($max-width: '660px') {
     @include frame("large");
   }
 }
 
 &-xlarge {    
   @include responsive($min-width: '660px') {
     @include frame("xxlarge");
   }

   @include responsive($max-width: '660px') {
     @include frame("large");
   }
 }

 &-vertical {
   @include frame("default", "vertical");

   &-min {
     @include frame("min", "vertical");
   }

   &-small {
     @include frame("small", "vertical");
   }

   &-medium {
     @include frame("medium", "vertical");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include frame("large", "vertical");
     }

     @include responsive($max-width: '660px') {
       @include frame("medium", "vertical");
     }
   }
 }

 &-horizontal {
   @include frame("default", "horizontal");

   &-min {
     @include frame("min", "horizontal");
   }

   &-small {
     @include frame("small", "horizontal");
   }

   &-medium {
     @include frame("medium", "horizontal");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include frame("large", "horizontal");
     }

     @include responsive($max-width: '660px') {
       @include frame("medium", "horizontal");
     }
   }
 }

 &-top {
   &-min {
     @include frame("min", "top");
   }

   &-small {
     @include frame("small", "top");
   }

   &-medium {
     @include frame("medium", "top");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include frame("large", "top");
     }

     @include responsive($max-width: '660px') {
       @include frame("medium", "top");
     }
   }
 }

 &-right {
   &-min {
     @include frame("min", "right");
   }

   &-small {
     @include frame("small", "right");
   }

   &-medium {
     @include frame("medium", "right");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include frame("large", "right");
     }

     @include responsive($max-width: '660px') {
       @include frame("medium", "right");
     }
   }
 }

 &-bottom {
   &-min {
     @include frame("min", "bottom");
   }

   &-small {
     @include frame("small", "bottom");
   }

   &-medium {
     @include frame("medium", "bottom");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include frame("large", "bottom");
     }

     @include responsive($max-width: '660px') {
       @include frame("medium", "bottom");
     }
   }
 }

 &-left {
   &-min {
     @include frame("min", "left");
   }

   &-small {
     @include frame("small", "left");
   }

   &-medium {
     @include frame("medium", "left");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include frame("large", "left");
     }

     @include responsive($max-width: '660px') {
       @include frame("medium", "left");
     }
   }
 }
}

.margin {
 @include margin;

 &-min {
   @include margin("min", "default");
 }

 &-small {
   @include margin("small", "default");
 }

 &-medium {
   @include margin("medium", "default");
 }

 &-large {
   @include responsive($min-width: '660px') {
     @include margin("large", "default");
   }

   @include responsive($max-width: '660px') {
     @include margin("medium", "default");
   }
 }
 
 &-xlarge {    
   @include responsive($min-width: '660px') {
     @include margin("xlarge", "default");
   }

   @include responsive($max-width: '660px') {
     @include margin("medium", "default");
   }
 }

  &-xxlarge {    
    @include responsive($min-width: '660px') {
      @include margin("xxlarge", "default");
    }

    @include responsive($max-width: '660px') {
      @include margin("medium", "default");
    }
  }

 &-vertical {
   @include margin("default", "vertical");

   &-min {
     @include margin("min", "vertical");
   }

   &-small {
     @include margin("small", "vertical");
   }

   &-medium {
     @include margin("medium", "vertical");
   }

   &-large {      
     @include responsive($min-width: '660px') {
       @include margin("large", "vertical");
     }

     @include responsive($max-width: '660px') {
       @include margin("medium", "vertical");
     }
   }
 }

 &-horizontal {
   @include margin("default", "horizontal");

   &-min {
     @include margin("min", "horizontal");
   }

   &-small {
     @include margin("small", "horizontal");
   }

   &-medium {
     @include margin("medium", "horizontal");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include margin("large", "horizontal");
     }

     @include responsive($max-width: '660px') {
       @include margin("medium", "horizontal");
     }
   }
 }

 &-top {
   @include margin("default", "top");

   &-min {
     @include margin("min", "top");
   }

   &-small {
     @include margin("small", "top");
   }

   &-medium {
     @include margin("medium", "top");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include margin("large", "top");
     }

     @include responsive($max-width: '660px') {
       @include margin("medium", "top");
     }
   }
   
   &-large {
     @include responsive($min-width: '660px') {
       @include margin("xlarge", "top");
     }

      @include responsive($max-width: '660px') {
        @include margin("medium", "top");
      }
    }
    
    &-xxlarge {
      @include responsive($min-width: '660px') {
        @include margin("xxlarge", "top");
      }

     @include responsive($max-width: '660px') {
       @include margin("medium", "top");
     }
   }
 }

 &-right {
   @include margin("default", "right");

   &-min {
     @include margin("min", "right");
   }

   &-small {
     @include margin("small", "right");
   }

   &-medium {
     @include margin("medium", "right");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include margin("large", "right");
     }

     @include responsive($max-width: '660px') {
       @include margin("medium", "right");
     }
   }
 }

 &-bottom {
   @include margin("default", "bottom");

   &-min {
     @include margin("min", "bottom");
   }

   &-small {
     @include margin("small", "bottom");
   }

   &-medium {
     @include margin("medium", "bottom");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include margin("large", "bottom");
     }

     @include responsive($max-width: '660px') {
       @include margin("medium", "bottom");
     }
   }
 }

 &-left {
   @include margin("default", "left");

   &-min {
     @include margin("min", "left");
   }

   &-small {
     @include margin("small", "left");
   }

   &-medium {
     @include margin("medium", "left");
   }

   &-large {
     @include responsive($min-width: '660px') {
       @include margin("large", "left");
     }

     @include responsive($max-width: '660px') {
       @include margin("medium", "left");
     }
   }
 }
}

.auto-margins {
 margin-left: auto;
 margin-right: auto;
}

.border {
 @include border;
 
 &-top {
   @include border($direction: 'top');
 }
 
 &-right {
   @include border($direction: 'right');
 }
 
 &-bottom {
   @include border($direction: 'bottom');
 }
 
 &-left {
   @include border($direction: 'left');
 }
 
 &-horizontal {
   @include border($direction: 'horizontal');
 }
 
 &-vertical {
   @include border($direction: 'vertical');
 }
 
 &-top-light {
   @include border($color: 'light', $direction: 'top');
 }
 
 &-right-light {
   @include border($color: 'light', $direction: 'right');
 }
 
 &-bottom-light {
   @include border($color: 'light', $direction: 'bottom');
 }
 
 &-left-light {
   @include border($color: 'light', $direction: 'left');
 }
 
 &-horizontal-light {
   @include border($color: 'light', $direction: 'horizontal');
 }
 
 &-vertical-light {
   @include border($color: 'light', $direction: 'vertical');
 }
 
 &-radius {
   &-box {
     border-radius: get('ui.border.radius.box');
     
     &-top-right {
       border-top-right-radius: get('ui.border.radius.box');
     }
     
     &-top-left {
       border-top-left-radius: get('ui.border.radius.box');
     }
     
     &-bottom-right {
       border-bottom-right-radius: get('ui.border.radius.box');
     }
     
     &-bottom-left {
       border-bottom-left-radius: get('ui.border.radius.box');
     }
   }
 }
}

.no {
 &-border {
   border: none !important;
 }

 &-margin {
   margin: 0 !important;
 }

 &-padding {
   padding: 0 !important;
 }

 &-background,
 &-bg {
   background: transparent !important;
 }
}

.grid {
 display: grid;
 
 &.content {
   row-gap: get('ui.air.xlarge');
 }
 
 &.two-columns {
   grid-template-columns: repeat(2, 1fr);
   column-gap: get('ui.air.default');
   row-gap: get('ui.air.xlarge');
   
   @include responsive($max-width: '960px') {
     grid-template-columns: repeat(1, 1fr);
   }
 }
 
 &.three-columns {
   grid-template-columns: repeat(3, 1fr);
   column-gap: get('ui.air.large');
   row-gap: get('ui.air.xlarge');
   
   @include responsive($min-width: '960px') {
     &.gutter-large {
       column-gap: 4em;
     }
   }
   
   @include responsive($max-width: '960px') {
     grid-template-columns: repeat(1, 1fr);
   }
 }
 
 &.gallery {
   grid-template-columns: repeat(2, 1fr);
   grid-gap: get('ui.air.medium');
 }
 
 @include responsive($min-width: '2200px') {
   &.gallery {
     grid-template-columns: repeat(3, 1fr);
   }
 }
 
 @include responsive($max-width: '1400px') {
   &.gallery {
     grid-template-columns: repeat(1, 1fr);
   }
 }
}

.w {
 &-full {
   width: 100%;
 }
 
 &-half {
   width: 50%;
 }
 
 &-third {
   width: 33.333333%;
 }
 
 &-quarter {
   width: 25%;
 }
 
 &-zero {
   width: 0;
 }
}

.align {
 &-top {
   align-items: flex-start;
 }

 &-center {
   align-items: center;
 }

 &-bottom {
   align-items: flex-end;
 }

 &-left {
   justify-content: flex-start;
 }

 &-right {
   justify-content: flex-end;
 }

 &-stretch {
   align-items: stretch;
 }

 &-self {
   &-top {
     align-self: flex-start;
   }

   &-center {
     align-self: center;
   }

   &-bottom {
     align-self: bottom;
   }
 }

 &-content {
   &-left {
     align-content: left;
   }

   &-right {
     align-content: right;
   }
 }

 &-text {
   &-left {
     text-align: left !important;
   }

   &-center {
     text-align: center !important;
   }

   &-right {
     text-align: right !important;
   }
 }
}

.justify {
 &-center {
   justify-content: center;
 }
 
 &-right {
   justify-content: flex-end;
 }
 
 &-left {
   justify-content: flex-start;
 }
}

.text-align {
 &-center {
   text-align: center;
 }
 
 &-left {
   text-align: left;
 }
 
 &-right {
   text-align: right;
 }
}

.space {
 &-around {
   justify-content: space-around;
 }

 &-between {
   justify-content: space-between;
 }

 &-evenly {
   justify-content: space-evenly;
 }
}

.grow {
 &-none {
   flex-grow: 0;
 }

 &-one {
   flex-grow: 1;
 }

 &-two {
   flex-grow: 2;
 }

 &-three {
   flex-grow: 3;
 }
}

.place {
 &-right {
   margin-left: auto;
 }

 &-bottom {
   margin-top: auto;
 }
}

.flex {
 &-rows {
   display: flex;
   flex-flow: row nowrap;
   
   &.gutter {
     & > * {
       margin-right: get('ui.air.default');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter-small {
     & > * {
       margin-right: get('ui.air.medium');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter-xsmall {
     & > * {
       margin-right: get('ui.air.small');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter-min {
     & > * {
       margin-right: get('ui.air.min');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter-large {
     & > * {
       margin-right: get('ui.air.xlarge');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter,
   &.gutter-small,
   &.gutter-xsmall,
   &.gutter-large {
     &:last-child {
       margin-right: 0;
     }
   }
   
   &[class*="stack-on-"] {
     [class*="grid-"] {
       width: 100%;
     }
   }
   
   &.stack-on-laptop {
     @include responsive($max-width: get('ui.responsive.laptop')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
   
   &.stack-on-tablet {
     @include responsive($max-width: get('ui.responsive.tablet')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
   
   &.stack-on-smallscreen {
     @include responsive($max-width: get('ui.responsive.smallscreen')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
   
   &.stack-on-tablet {
     @include responsive($max-width: get('ui.responsive.tablet')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
   
   &.stack-on-mobile {
     @include responsive($max-width: get('ui.responsive.mobile.default')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
   
   &.stack-on-mobile-lg {
     @include responsive($max-width: get('ui.responsive.mobile.large')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
   
   &.stack-on-mobile-m {
     @include responsive($max-width: get('ui.responsive.mobile.medium')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
   
   &.stack-on-mobile-s {
     @include responsive($max-width: get('ui.responsive.mobile.small')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
   
   &.stack-on-mobile-xs {
     @include responsive($max-width: get('ui.responsive.mobile.min')) {
       display: block;
       
       &.gutter,
       &.gutter-small,
       &.gutter-xsmall,
       &.gutter-large {
         & > * {
           margin-right:0;
         }
       }
     
       &.gutter {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-small {
         & > * {
           margin-bottom: get('ui.air.medium');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     
       &.gutter-xsmall {
         & > * {
           margin-bottom: get('ui.air.small');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-min {
         & > * {
           margin-bottom: get('ui.air.min');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }

       &.gutter-large {
         & > * {
           margin-bottom: get('ui.air.default');

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
     }
   }
 }

 &-columns {
   display: flex;
   flex-flow: column nowrap;
   align-items: flex-start;
   
   &.gutter {
     & > * {
       margin-bottom: get('ui.air.default');
     }
   }
   
   &.gutter-small {
     & > * {
       margin-bottom: get('ui.air.small');
     }
   }
   
   &.gutter-xsmall {
     & > * {
       margin-bottom: get('ui.air.min');
     }
   }
   
   &.gutter-large {
     & > * {
       margin-bottom: get('ui.air.xlarge');
     }
   }
   
   &.gutter,
   &.gutter-small,
   &.gutter-xsmall,
   &.gutter-large {
     &:last-child {
       margin-bottom: 0;
     }
   }
   
   &.align-center {
     align-items: center;
   }
   
   &.align-right {
     align-items: flex-end;
   }
 }
}

[class*="fixed-width-"] {
 flex-shrink: 0;
}

.fixed-width {
 &-10 {
   width: 10px;
 }
 
 &-20 {
   width: 20px;
 }
 
 &-30 {
   width: 30px;
 }
 
 &-40 {
   width: 40px;
 }
 
 &-50 {
   width: 50px;
 }
 
 &-60 {
   width: 60px;
 }
 
 &-100 {
   width: 100px;
 }
 
 &-6 {
   width: 6px;
 }
 
 &-12 {
   width: 12px;
 }
 
 &-24 {
   width: 24px;
 }
 
 &-32 {
   width: 32px;
 }
 
 &-48 {
   width: 48px;
 }
}

.hide {
 display: none !important;
 
 &-on-laptop {
   @include responsive($max-width: get('ui.responsive.laptop')) {
     display: none;
   }
 }
   
 &-on-tablet {
   @include responsive($max-width: get('ui.responsive.tablet')) {
     display: none;
   }
 }

 &-on-smallscreen {
   @include responsive($max-width: get('ui.responsive.smallscreen')) {
     display: none;
   }
 }

 &-on-tablet {
   @include responsive($max-width: get('ui.responsive.tablet')) {
     display: none;
   }
 }

 &-on-mobile {
   @include responsive($max-width: get('ui.responsive.mobile.default')) {
     display: none;
   }
 }

 &-on-mobile-lg {
   @include responsive($max-width: get('ui.responsive.mobile.large')) {
     display: none;
   }
 }

 &-on-mobile-m {
   @include responsive($max-width: get('ui.responsive.mobile.medium')) {
     display: none;
   }
 }

 &-on-mobile-s {
   @include responsive($max-width: get('ui.responsive.mobile.small')) {
     display: none;
   }
 }

 &-on-mobile-xs {
   @include responsive($max-width: get('ui.responsive.mobile.min')) {
     display: none;
   }
 }
}

.show {
 
}

.d {
 &-none {
   display: none;
 }
 
 &-block {
   display: block;
 }
 
 &-flex {
   display: flex;
 }
}

img {
 &.d-block {
   width: 100%;
   height: auto;
 }
}

a {
 text-decoration: none;
 color: get('color.primary.default');
 font-weight: 500;
}

strong {
 color: get('color.font.headline');
 font-weight: 500;
}

.type {
 &-large {
    font-size: 1.75em;
   line-height: 1.3;
   letter-spacing: -0.02em;
   font-weight: 300;
   
   @include responsive($max-width: get('ui.responsive.mobile.default')) {
     font-size: 1.5em;
   }
 }
 
 &-medium {
    font-size: 1.375em;
   line-height: 1.5;
   font-weight: 300;
 }
 
 &-note {
   font-size: get('ui.font.size.small');
   color: get('color.font.medium');
 }
 
 &-xsmall {
   font-size: get('ui.font.size.xsmall');
   color: get('color.font.medium');
   line-height: 1.2;
 }
}

.centerbox {
  margin-left: auto;
  margin-right: auto;
}

.textblock {
  width: 100%;
 max-width: 720px;
 margin-left: auto;
 margin-right: auto;
}

.image-frame {
 display: flex;
 align-items: center;
 position: relative;
 
 & > img {
   display: block;
   width: 100%;
   height: auto;
 }
 
 &.icon {
   & > img {
     width: auto;
     height: 42px;
   }
 }
 
 &.round-corners {
   border-radius: get('ui.border.radius.box');
   overflow: hidden;
   
   img {
     border-radius: get('ui.border.radius.box');
     overflow: hidden;
   }
 }
}

.video {
 &-play,
 &-pause {
   display: flex;
   flex-flow: row nowrap;
   align-items: center;
   justify-content: center;
   width: 44px;
   height: 44px;
   border-radius: get('ui.border.radius.button');
   background-color: get('color.primary.default');
   cursor: pointer;
   
   i {
     color: #FFF;
   }
 }
 
 &-controls {
   @include box('white');
   display: flex;
   flex-flow: row nowrap;
   width: calc(100% - 16px);
   padding: 8px;
   position: absolute;
   left: 8px;
   bottom: 8px;
   z-index: 2;
   
   .video-play,
   .video-pause {
     margin-right: 8px;
   }
   
   .video-title {
     display: block;
     font-weight: 700;
     margin-bottom: 2px;
     font-size: 15px;
     text-align: left;
   }
   
   form {
     .input-checkbox {
       margin-left: 2px;
       
       label {
         font-size: 14px;
       }
     }
   }
 }
 
 &-frame {
   border-radius: get('ui.border.radius.box');
   overflow: hidden;
 }
 
 &-box {
   position: relative;
   border-radius: get('ui.border.radius.box');
   overflow: hidden;
   
   .video-pause {
     display: none;
   }
   
   &.is-playing {
     .video-play {
       display: none;
     }
     
     .video-pause {
       display: flex;
     }
   }
   
   &.vertical {
     &.small {
       max-width: 320px;
       
       .video-frame {
         position: absolute;
         width: 100%;
         top: 0;
         left: 0;
       }
     }
   }
 }
}

.user-thumbnail {
  display: block;
  
  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 100%;
  }
  
  &.default {
    width: 90px;
    height: 90px;
  }
}

blockquote {
  figure {
    flex-shrink: 0;
  }
  
  p {
    font-size: 1.425em;
    letter-spacing: -0.5px;
  }
  
  cite {
    display: block;
    font-weight: 700;
    font-style: normal;
    color: get('color.font.dark');
    margin-top: 0.5em;
  }
  
  @include responsive($max-width: get('ui.responsive.mobile.large')) {
    p {
      font-size: 1.325em;
      letter-spacing: -0.325px;
    }
  }
  
  @include responsive($max-width: get('ui.responsive.mobile.medium')) {
    p {
      font-size: 1.295em;
      letter-spacing: 0;
    }
  }
}

.testimonial {
  &.default {
    display: flex;
    width: 100%;
    
    figure {
      margin: 0 get('ui.air.default') 0 0;
      position: relative;

      &:after {
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        background-image: url('assets/voicewave/icon-quote.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        bottom: -31px;
        left: 50%;
        margin-left: -12px;
      }
    }
  }
  
  @include responsive($min-width: get('ui.responsive.mobile.large')) {
    &.default {
      flex-flow: row nowrap;
      
      .content {
        flex-grow: none;
      }
    }
  }
}

.testimonials {
  & > .testimonial {
    margin-bottom: 6em;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.color {
 &-primary {
   color: get('color.primary.default');
 }
 
 &-secondary {
   color: get('color.secondary.default');
 }
 
 &-tertiary {
   color: get('color.tertiary.default');
 }
 
 &-medium {
    color: get('color.font.medium');
  }
  
  &-medium-light {
   color: get('color.grey.medium');
 }
 
 &-light {
   color: get('color.grey.light');
 }
}

.bg {
 &-white {
   background-color: white !important;
 }
 
 &-default {
   background-color: get('color.grey.background') !important;
 }
}

.box {
 &.default {
   @include box;
 }
 
 &.grey {
   @include box('grey');
 }

 &.white {
  @include box('white');
}
 
 &.shadow {
   @include box('shadow');
 }
 
 &.shadow-hard {
   @include box('shadow-hard');
 }
 
 &.popup {
   @include box('popup');
 }
 
 &.inline {
   display: inline-flex;
 }
}

.tag {
 &-keyword,
 &-icon,
 &-overlay {
   display: inline-flex;
   height: get('ui.tag.default');
   align-items: center;
   justify-content: center;
   border-radius: get('ui.border.radius.tag');
   font-size: get('ui.font.size.tag.default');
 }
 
 &-keyword,
 &-overlay {
   height: get('ui.tag.default');
   @include frame('small','horizontal');
 }
 
 &-icon {
   width: get('ui.tag.default');
   color: #FFF;
   background-color: get('color.primary.medium');
   
   i {
     font-size: 90%;
   }
 }
}

.popup {
 @include box('popup');
 
 &-close {
   @include hover('scale-large');
   display: flex;
   align-items: center;
   justify-content: center;
   width: get('ui.icon.min');
   height: get('ui.icon.min');
   background-color: get('color.grey.light');
   mask-image: url('/assets/home/icon-cross.svg');
   mask-size: contain;
   mask-repeat: no-repeat;
   -webkit-mask-image: url('/assets/home/icon-cross.svg');
   -webkit-mask-size: contain;
   -webkit-mask-repeat: no-repeat;
   background-position: center center;
   position: absolute;
   top: get('ui.icon.min') + 2px;
   right: get('ui.icon.min') + 2px;
   cursor: pointer;
   z-index: 1;
   
   &:hover {
     background-color: get('color.grey.medium');
     transition: background-color 0.2s ease-in-out;
   }
 }
 
 & > .header {
   .menu-horizontal {
     align-self: flex-end;
     width: 100%;
   }
   
   & > h4,
   & > .title {
     .count {
       font-weight: 300;
       font-size: 85%;
     }
   }
   
   &:not(.small) {
     padding-top: 0 !important;
     height: get('ui.toolbar.large');
   }
 }
 
 & > .content {
   & > nav {
     margin-top: -12px;
   }
   
   & > .item-mention {
     margin-bottom: get('ui.air.medium');
   }
   
   & > p,
   & > .content,
   & > .message {
     margin-bottom: get('ui.air.mediumLarge');
   }
 }
 
 &[class*="pointer-"] {
   &:after {
     display: flex;
     content: "";
     align-items: center;
     justify-content: center;
     background-position: center center;
     background-repeat: no-repeat;
     background-size: contain;
     position: absolute;
   }
 }
 
 &.pointer {
   &-top-left,
   &-top-center,
   &-top-right,
   &-bottom-left,
   &-bottom-center,
   &-bottom-right {
     &:after {
       width: 26px;
       height: 14px;
     }
   }
   
   &-left-top,
   &-left-center,
   &-left-bottom,
   &-right-top,
   &-right-center,
   &-right-bottom {
     &:after {
       width: 14px;
       height: 26px;
     }
   }
   
   &-top-left,
   &-top-center,
   &-top-right {
     &:after {
       background-image: url("/assets/home/pointer-up.svg");
       top: -11px;
     }
   }
   
   &-bottom-left,
   &-bottom-center,
   &-bottom-right {
     &:after {
       background-image: url("/assets/home/pointer-down.svg");
       bottom: -11px;
     }
   }
   
   &-top,
   &-bottom {
     &-left {
       &:after {
         left: 18px;
       }
     }
     
     &-center {
       &:after {
         left: 50%;
         margin-left: -13px;
       }
     }
     
     &-right {
       &:after {
         right: 18px;
       }
     }
   }
   
   &-left-top,
   &-left-center,
   &-left-bottom {
     &:after {
       background-image: url("/assets/home/pointer-left.svg");
       left: -11px;
     }
   }
   
   &-right-top,
   &-right-center,
   &-right-bottom {
     &:after {
       background-image: url("/assets/home/pointer-right.svg");
       right: -11px;
     }
   }
   
   &-left,
   &-right {
     &-top {
       &:after {
         top: 18px;
       }
     }
     
     &-center {
       &:after {
         top: 50%;
         margin-top: -13px;
       }
     }
     
     &-bottom {
       &:after {
         bottom: 18px;
       }
     }
   }
 }
}

.modal {
 position: fixed;
 z-index: 1000;
 
 &-close {
   @include hover('scale-large');
   display: flex;
   align-items: center;
   justify-content: center;
   width: get('ui.icon.min');
   height: get('ui.icon.min');
   background-color: get('color.grey.light');
   mask-image: url('/assets/home/icon-cross.svg');
   mask-size: contain;
   mask-repeat: no-repeat;
   background-position: center center;
   position: absolute;
   top: get('ui.icon.min') + 2px;
   right: get('ui.icon.min') + 2px;
   cursor: pointer;
   z-index: 1;
   
   &:hover {
     background-color: get('color.grey.medium');
     transition: background-color 0.2s ease-in-out;
   }
 }
 
 &-dialog {
   @include box('popup');
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   
   @include responsive($min-width: #{get('ui.modal.default') + 48px}) {
     max-width: get('ui.modal.default');
   }
 }
 
 &-header {
   .menu-horizontal {
     align-self: flex-end;
     width: 100%;
   }
   
   & > h4,
   & > .title {
     .count {
       font-weight: 300;
       font-size: 85%;
     }
   }
   
   &:not(.small) {
     padding-top: 0 !important;
     height: get('ui.toolbar.large');
   }
 }
 
 &-body {
   & > nav {
     margin-top: -12px;
   }
   
   & > .item-mention {
     margin-bottom: get('ui.air.medium');
   }
   
   & > p,
   & > .content,
   & > .message {
     margin-bottom: get('ui.air.mediumLarge');
   }
 }

 &.xlarge {
   .modal-dialog {
     max-width: get('ui.modal.max');
   }
 }
 
 &.xlarge {
   .modal-dialog {
     @include responsive($min-width: #{get('ui.modal.xlarge') + 48px}) {
       max-width: get('ui.modal.xlarge');
     }
   }
 }

 &.large {
   .modal-dialog {
     @include responsive($min-width: #{get('ui.modal.large') + 48px}) {
       max-width: get('ui.modal.large');
     }
   }
 }

 &.small {
   .modal-dialog {
     @include responsive($min-width: #{get('ui.modal.small') + 48px}) {
       max-width: get('ui.modal.small');
     }
   }
 }

 &.xsmall {
   .modal-dialog {
     @include responsive($min-width: #{get('ui.modal.xsmall') + 48px}) {
       max-width: get('ui.modal.xsmall');
     }
   }
 }
}

.button {
 &-primary {
   @include button('default','primary');
   
   &.xound-waves {
     &:before {
       display: flex;
       content: "";
       width: 30px;
       height: 20px;
       background-image: url('/assets/home/xound-waves.svg');
       background-repeat: no-repeat;
       background-position: center center;
       background-size: contain;
       margin-right: get('ui.air.medium');
     }
   }
 }
 
 &-secondary {
   @include button('default','secondary');
 }
 
 &-icon {
   @include button('default','icon');
 }
 
 &-text {
   display: inline-flex;
   align-items: center;
   font-weight: 600;
   color: get('color.primary.default');
   
   i {
     color: get('color.primary.default');
     font-size: 85%;
     margin-right: 0.35em;
   }
   
   &:hover {
     color: get('color.primary.dark');
     transition: all 0.2s ease-in-out;
   }
   
   &.small {
     font-size: get('ui.font.size.small');
   }
 }
  
  &-tool {
    @include button('default','tool');
  }
  
  &-mega {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 3em;
    cursor: pointer;
    
    & > * {
      margin-right: 1em;
      
      &:last-child {
        margin-right: 0;
      }
    }
    
    .icon {
      width: auto;
      height: 30px;
    }
    
    .label {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: -0.75px;
    }
    
    @include responsive($max-width: get('ui.responsive.smallscreen')) {
      .icon {
        height: 24px;
      }

      .label {
        font-size: 26px;
        letter-spacing: -0.5px;
      }
    }
    
    @include responsive($max-width: get('ui.responsive.mobile.small')) {
      padding: 2em;
      
      .icon {
        height: 20px;
      }

      .label {
        font-size: 22px;
      }
    }
  }
}

.tool-group {
  & > * {
    margin-right: 6px;
    
    &:last-child {
      margin-right: 0;
    }
  }
}

.icon {
 &-size {
   &-xsmall {
     font-size: 60% !important;
   }
   
   &-small {
     font-size: 80% !important;
   }
   
   &-medium {
     font-size: 120% !important;
   }
   
   &-large {
     font-size: 150% !important;
   }
   
   &-xlarge {
     font-size: 200% !important;
   }
 }
 
 &-box {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   width: get('ui.icon.default');
   height: get('ui.icon.default');
   flex-shrink: 0;
   border-radius: get('ui.border.radius.box');
   background-color: get('color.grey.lighter');
   
   i {
     color: get('color.primary.default');
     font-size: 90%  !important;
   }
   
   svg {
     path {
       fill: get('color.primary.default');
     }
   }
   
   &.small {
     width: get('ui.icon.small');
     height: get('ui.icon.small');
     border-radius: get('ui.border.radius.input');
     
     i {
       font-size: 70% !important;
     }
   }
   
   &.large {
     width: get('ui.icon.large');
     height: get('ui.icon.large');
     border-radius: get('ui.border.radius.large');
     
     i {
       font-size: 130% !important;
     }
   }
   
   &.border {
     @include border;
     background-color: #FFF;
   }
   
   &.no-bg {
     background-color: transparent;
   }
   
   &.solid {
     i {
       color: #FFF;
     }
     
     svg {
       path {
         fill: #FFF;
       }
     }
     
     &.red {
       background-color: get('color.alerts.red');
     }
     
     &.blue {
       background-color: get('color.alerts.blue');
     }
     
     &.green {
       background-color: get('color.alerts.green');
     }
     
     &.dark {
       background-color: get('color.grey.dark');
     }
     
     &.medium-dark {
       background-color: get('color.grey.dark');
     }
     
     &.primary {
       background-color: get('color.primary.default');
     }
     
     &.secondary {
       background-color: get('color.secondary.default');
     }
   }
 }
  }

button {
 font-family: proxima-nova, sans-serif !important;
 font-feature-settings: 'ss02' !important;
}

[class*="list-"] {
 list-style: none;
}

.list {
  &-checkmarks {
   margin-top: get('ui.air.default');
   
   li {
     margin-bottom: 0.5em;
     position: relative;
     padding-left: 24px;

     &:before {
       display: inline-flex;
       font-family: "Font Awesome 6 Pro";
       content: "\f00c";
       font-size: 80%;
       font-weight: 900;
       color: get('color.primary.default');
       position: absolute;
       left: 0;
       top: 4px;
     }
     
     &:last-child {
       margin-bottom: 0;
     }
   }
 }
  
  &-icons {
    li {
      display: flex;
      align-items: center;
      
      .icon {
        display: flex;
        margin-right: 0.5em;
      }
      
      .label {
        font-size: 18px;
        color: get('color.font.medium');
      }
    }
    
    @include responsive($min-width: get('ui.responsive.mobile.large')) {
      &.horizontal {
        display: flex;
        flex-flow: row nowrap;

        li {
          margin-right: 3em;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    
    @include responsive($max-width: get('ui.responsive.smallscreen')) {
      &.horizontal {
        li {
          margin-right: 2em;
        }
      }
    }
    
    @include responsive($max-width: get('ui.responsive.mobile.large')) {
      li {
        margin-bottom: 0.75em;
      }
      
      &.horizontal {
        li {
          margin-right: 0;
        }
      }
    }
  }
}

[class*="menu-"] {
 .menu-tab {
   list-style: none;
   padding: 0;
   margin: 0;
   font-weight: 500;
   font-size: get('ui.font.size.menu');
   user-select: none;
   cursor: pointer;
   position: relative;
   
   &,
   & > a,
   & > .sub-tab-toggle {
     & > .menu-label {
       color: get('color.grey.medium');
       font-weight: inherit;
       font-size: inherit;
     }

     & > .menu-icon {
       display: inline-flex;
       width: 16px;
       font-size: 90%;
       
       &:before {
         color: get('color.grey.medium');
       }
     }
   }
   
   &:hover {
     &:not(.active) {
       &,
       & > a,
       & > .sub-tab-toggle {
         color: get('color.font.headline');
         transition: color 0.2s ease-in;
         
         & > .menu-label {
           color: get('color.font.headline');
           transition: color 0.2s ease-in;
         }
         
         & > i,
         & > .menu-icon {
           cursor: pointer;
           
           &:before {
             color: get('color.secondary.medium');
             transition: all 0.2s ease-in-out;
           }
           
           svg,
           path {
             cursor: pointer;
           }

           path {
             fill: get('color.secondary.medium');
             transition: all 0.2s ease-in-out;
           }
         }
       }
     }
   }
   
   &.active {
     &,
     & > a,
     & > .sub-tab-toggle {
       & > i,
       & > .menu-icon {
         color: get('color.primary.default');

         path {
           fill: get('color.primary.default');
         }
       }
       
       & > .menu-label {
         color: get('color.primary.default');
         font-weight: 600;
       }
     }
   }
 }
}

.menu {
 &-close {
   @include hover('scale-large');
   display: flex;
   align-items: center;
   justify-content: center;
   width: get('ui.icon.min');
   height: get('ui.icon.min');
   background-color: get('color.grey.light');
   mask-image: url('/assets/home/icon-cross.svg');
   mask-size: contain;
   mask-repeat: no-repeat;
   -webkit-mask-image: url('/assets/home/icon-cross.svg');
   -webkit-mask-size: contain;
   -webkit-mask-repeat: no-repeat;
   background-position: center center;
   position: absolute;
   top: get('ui.icon.min') + 2px;
   right: get('ui.icon.min') + 2px;
   cursor: pointer;
   z-index: 1;
   
   &:hover {
     background-color: get('color.grey.medium');
     transition: background-color 0.2s ease-in-out;
   }
 }
 
 &-horizontal,
 &-vertical {
   display: flex;
   overflow-x: visible;
   
   .menu-tab {
     display: flex;
     flex-flow: row nowrap;
     align-items: center;
     
     i {
       display: inline-flex;
       align-items: center;
       justify-content: center;
     }
     
     .menu-label {
       color: get('color.font.medium');
       
       &.small {
         font-size: get('ui.font.size.small');
       }
       
       &.xsmall {
         font-size: get('ui.font.size.xsmall');
       }
       
       &.min {
         font-size: get('ui.font.size.min');
       }
       
       .count {
         display: inline-block;
         font-size: 80%;
         font-weight: 400;
         transform: translatey(-1px);
       }
     }
     
     &.flex-columns {
       .tool,
       .button-tool,
       .button-icon {
         & + .menu-label {
           &.min {
             margin-top: 3px;
           }
           
           &.xsmall {
             margin-top: 4px;
           }
         }
       }
     }
     
     &:hover {
       z-index: 1;
     }
   }
 }
 
 &-horizontal {
   & > .menu-tab {
     margin-right: get('ui.air.default');
     
     &:last-child {
       margin-right: 0;
     }
   }
   
   &.gutter {
     & > * {
       margin-right: get('ui.air.default');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter-small {
     & > * {
       margin-right: get('ui.air.medium');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter-xsmall {
     & > * {
       margin-right: get('ui.air.small');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter-min {
     & > * {
       margin-right: get('ui.air.min');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter-large {
     & > * {
       margin-right: get('ui.air.xlarge');
       
       &:last-child {
         margin-right: 0;
       }
     }
   }
   
   &.gutter,
   &.gutter-small,
   &.gutter-xsmall,
   &.gutter-large {
     &:last-child {
       margin-right: 0;
     }
   }
 }
 
 &-vertical {
   flex-flow: column nowrap;
   
   & > .menu-tab {
     padding: 6px 0;
     
     &,
     & > a,
     & > .sub-tab-toggle {
       & > i {
         margin-right: 0.5em;
       }
     }
     
     .menu-label {
       text-align: center;
     }
   }
   
   &.tabs {
     &:not(.to-right) {
       @include border($direction: 'right');
       
       & > .menu-tab {
         @include frame('large','right');
         
         &.active {
           box-shadow: 2px 0 0 0 get('color.primary.default');
         }
       }
     }
     
     &.to-right {
       @include border($direction: 'left');
       
       .menu-tab {
         @include frame('large','left');
         
         &.active {
           box-shadow: -2px 0 0 0 get('color.primary.default');
         }
       }
     }
   }
   
   &.gutter {
     & > * {
       margin-bottom: get('ui.air.default');
       
       &:last-child {
         margin-bottom: 0;
       }
     }
   }
   
   &.gutter-small {
     & > * {
       margin-bottom: get('ui.air.medium');
       
       &:last-child {
         margin-bottom: 0;
       }
     }
   }
   
   &.gutter-xsmall {
     & > * {
       margin-bottom: get('ui.air.small');
       
       &:last-child {
         margin-bottom: 0;
       }
     }
   }
   
   &.gutter-min {
     & > * {
       margin-bottom: get('ui.air.min');
       
       &:last-child {
         margin-bottom: 0;
       }
     }
   }
   
   &.gutter-large {
     & > * {
       margin-bottom: get('ui.air.xlarge');
       
       &:last-child {
         margin-bottom: 0;
       }
     }
   }
   
   &.gutter,
   &.gutter-small,
   &.gutter-xsmall,
   &.gutter-large {
     &:last-child {
       margin-bottom: 0;
     }
   }
 }
 
 &-toggle {
   $s: 40px;
   display: none;
   width: $s;
   height: $s;
   position: relative;
   cursor: pointer;

   .bars {
     width: 20px;
     height: 18px;
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg);
     transition: 0.5s ease-in-out;
     position: absolute;
     top: 50%;
     left: 50%;
     margin-left: -10px;
     margin-top: -10px;

     span {
       display: block;
       position: absolute;
       width: 100%;
       height: 2px;
       background: get('color.font.light');
       border-radius: 9px;
       opacity: 1;
       left: 0;
       transform: rotate(0deg);
       transition: 0.25s ease-in-out;

       &:nth-child(1) {
         top: 0;
       }

       &:nth-child(2),
       &:nth-child(3) {
         top: 8px;
       }

       &:nth-child(4) {
         top: 16px;
       }
     }
   }

   &:hover {
     .bars {
       span {
         background-color: get('color.primary.default');
         transition: background-color 0.15s ease-out;
       }
     }
   }

   &.is-open {
     .bars {
       span {
         background-color: get('color.primary.default');
         transition: 0.15s ease-in-out;

         &:nth-child(1) {
           top: 18px;
           width: 0;
           left: 50%;
         }

         &:nth-child(2) {
           -webkit-transform: rotate(45deg);
           -moz-transform: rotate(45deg);
           -o-transform: rotate(45deg);
           transform: rotate(45deg);
         }

         &:nth-child(3) {
           -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
           -o-transform: rotate(-45deg);
           transform: rotate(-45deg);
         }

         &:nth-child(4) {
           top: 18px;
           width: 0;
           left: 50%;
         }
       }
     }
   }

   @include responsive($max-width: get('ui.responsive.mobile.default')) {
     display: block
   }
 }
}

.main-navigation {
 display: flex;
 flex-flow: row nowrap;
 align-items: center;

 
 .menu-horizontal {
   & > .menu-tab {
     &.signup {
       margin-right: 12px;
     }
   }
 }
 
 .menu-toggle {
   margin-left: get('ui.air.medium');
 }
  
 @include responsive($max-width: get('ui.responsive.mobile.xlarge')) {
   .menu-horizontal {
     & > .menu-tab {
       &:not(.signup):not(.login) {
         margin-right: get('ui.air.medium');
       }
     }
   }
 }
 
 @include responsive($max-width: get('ui.responsive.mobile.default')) {
   .menu-horizontal {
     display: none;
     width: 100%;
     height: auto;
     overflow: hidden;
     flex-direction: column;
     align-items: flex-end;
     background-color: #FFF;
     box-shadow: 
     0 8px 18px -6px rgba(get('color.grey.dark'),0.25);
     position: fixed;
     top: 100px;

     left: 0;

     &.active {
      display: flex;
    }
     
     & > .menu-tab {
      margin-right: 30px;
       &:not(.signup):not(.login) {
        flex-grow: 2;
         margin-bottom: get('ui.air.default');
         
         .menu-label {
           font-size: 1.2em;
           text-align: right;
         }
       }
       
       &.signup,
       &.login {
         position: fixed;
         top: 28px;
         z-index: 5;
       }
       
       &.signup {
         right: 112px;
       }
       
       &.login {
         right: 72px;
       }
     }
   }
   
   &.is-open {
     .menu-horizontal {
       max-height: 1200px;
       transition: max-height 0.6s ease-in-out;
       
       & > .menu-tab {
         &:not(.signup):not(.login) {
           opacity: 1;
           transition-delay: 9s;
           transition: opacity 0.2s ease-in-out;
         }
       }
     }
   }
 }
}



.dropdown {
 position: relative;
 
 &-toggle {
   .button-tool {
     width: 100%;
   }
 }
 
 &-panel {
   display: none;
   flex-flow: column nowrap;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 2;
   
   &.popup {
     min-width: 320px;
   }
   
   &.pointer-left-top {
     top: -18px;
     left: 36px;
   }
   
   &.pointer-top-right {
     top: 26px;
     left: auto;
     right: -18px;
   }
 }
 
 &.is-open {
   .dropdown-toggle {
     i,
     svg {
       color: get('color.primary.default');
       fill: get('color.primary.default');
     }
   }
   
   .dropdown-panel {
     display: inline-flex;
   }
 }
}

.tabs {
 &-panels {
   .tab-panel {
     display: none;
     
     &.active {
       display: block;
     }
   }
 }
}

@keyframes moveUp {
  0%   {transform: translatey(48px);}
  100% {transform: translatey(0);}
}

@keyframes moveDown {
  0%   {transform: translatey(-48px);}
  100% {transform: translatey(0);}
}

@keyframes moveRight {
  0%   {transform: translatex(-48px);}
  100% {transform: translatex(0);}
}

@keyframes moveLeft {
  0%   {transform: translatex(48px);}
  100% {transform: translatex(0);}
}

.aov-move-up {
  &.on-view,
  &.active {
    animation: moveUp 1s ease-out;
  }
}

.aov-move-down {
  &.on-view,
  &.active {
    animation: moveDown 1s ease-out;
  }
}

.aov-move-left {
  &.on-view,
  &.active {
    animation: moveLeft 1s ease-out;
  }
}

.aov-move-right {
  &.on-view,
  &.active {
    animation: moveRight 1s ease-out;
  }
}

//Form Element Styles
input {
 box-sizing: border-box;
 font-family: greycliff-cf, sans-serif !important;
 
 &:not([type="submit"]):not([type="button"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]) {
   @include border('input');
   outline: none;
   padding: 0 8px;
   font-size: get('ui.font.size.input');
   line-height: 0.925;
   min-height: get('ui.input.default');
   background-color: #FFF;
   border-radius: get('ui.border.radius.input');
   -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;

   &::placeholder {
     color: get('color.grey.medium');
     font-size: get('ui.font.size.placeholder');
   }

   &:hover {

   }

   &:focus {

   }

   &.small {
     min-height: get('ui.input.small');
     font-size: 15px;
   }
 }
}

label {
 font-size: get('ui.font.size.label');
 font-weight: 500;
 color: get('color.font.medium');
 margin-bottom: 0.25em;
}

textarea {
 @include border('input');
 outline: none;
 padding: 8px;
 font-size: get('ui.font.size.input');
 line-height: 0.925;
 min-height: get('ui.input.default');
 background-color: #FFF;
 border-radius: get('ui.border.radius.input');
 -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;

 &::placeholder {
   color: get('color.grey.medium');
   font-size: get('ui.font.size.placeholder');
 }
}

select {
 -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

fieldset {
 display: flex;
 flex-flow: column nowrap;
 border: none;
 margin-bottom: get('ui.air.medium');
}

.input-checkbox,
.input-radio {
 display: flex;
 flex-flow: row nowrap;
 align-items: center;
 user-select: none;
 margin-bottom: get('ui.air.min');
 
 label[for] {
   display: flex;
   align-items: center;
   margin-bottom: 0;
   cursor: pointer;
   position: relative;
   
   &:before {
     @include border;
     display: flex;
     align-items: center;
     justify-content: center;
     color: transparent;
     box-sizing: border-box;
     margin-right: get('ui.air.small');
   }
   
   &:hover {
     &:before {
       border-color: get('color.grey.medium')
     }
   }
 }
 
 input[type="checkbox"],
 input[type="radio"] {
   display: none;
   height: get('ui.input.checkbox');
 }
 
 &.hide-label {
   label[for] {
     font-size: 0;
     
     &:before {
       font-size: get('ui.font.size.label');
     }
   }
   
   &.small {
     label[for] {
       &:before {
         font-size: 11px;
       }
     }
   }
 }
 
 &.label-only {
   label[for] {
     &:before,
     &:after {
       display: none !important;
     }
   }
 }
 
 &:last-child {
   margin-bottom: 0;
 }
}

.input-checkbox {
 label[for] {
   min-width: get('ui.input.checkbox');
   height: get('ui.input.checkbox');
   position: relative;
   
   &:before {
     width: get('ui.input.checkbox');
     height: get('ui.input.checkbox');
     font-family: "Font Awesome 6 Pro";
     content: "\f00c";
     border-radius: get('ui.border.radius.input')
   }
   
   &:hover {
     &:before {
        border-color: darken(get('color.grey.light'),2%);
     }
   }
 }
 
 input[type="checkbox"] {
   &:checked {
     & + label[for] {
       &:before {
         background-color: get('color.primary.default');
         border-color: get('color.primary.default');
         color: #FFF;
       }
     }
   }
 }

 &.switch.grey {
  label[for] {
    &:before {
      background-color: get('color.grey.medium');
    }
  }
 }
 
 &.switch {
   label[for] {
     height: get('ui.input.switch');

     &:before {
       content: "";
       width: get('ui.input.switch') * 1.75;
       height: get('ui.input.switch');
       border-radius: get('ui.input.switch');
       background-color: get('color.grey.light');
     }
     
     &:after {
       display: block;
       content: "";
       width: get('ui.input.switch') - 6px;
       height: get('ui.input.switch') - 6px;
       background-color: #FFF;
       border-radius: 100%;
       position: absolute;
       left: 3px;
       top: 3px;
       transition: left 0.2s ease-in-out;
     }

     &:hover {
       &:before {
         border-color: transparent;
         background-color: get('color.grey.light');
         transition: all 0.2s ease-in-out;
       }
     }
   }
   
   input[type="checkbox"] {
     &:checked {
       & + label[for] {
         &:before {
           background-color: get('color.primary.default');
           border-color: get('color.primary.default');
         }
         
         &:after {
           left: get('ui.input.switch') - 2px;
           transition: left 0.3s ease-in-out;
         }
         
         &:hover {
           &:before {
             background-color: get('color.primary.dark');
           }
         }
       }
     }
   }
 }
 
 &.small {
   label[for] {
     min-width: get('ui.input.checkboxSmall');
     height: get('ui.input.checkboxSmall');
     
     &:before {
       width: get('ui.input.checkboxSmall');
       height: get('ui.input.checkboxSmall');
       border-radius: get('ui.border.radius.min');
       font-size: 11px;
     }
   }
   
   &.switch {
     label[for] {
       height: get('ui.input.switchSmall');

       &:before {
         width: get('ui.input.switchSmall') * 1.625;
         height: get('ui.input.switchSmall');
         border-radius: get('ui.input.switchSmall');
       }

       &:after {
         width: get('ui.input.switchSmall') - 6px;
         height: get('ui.input.switchSmall') - 6px;
         left: 3px;
         top: 3px;
       }
     }

     input[type="checkbox"] {
       &:checked {
         & + label[for] {
           &:after {
             left: get('ui.input.switchSmall') - 2px;
             transition: left 0.3s ease-in-out;
           }
         }
       }
     }
   }
 }
}

.input-radio {
 label[for] {
   min-width: get('ui.input.radio');
   height: get('ui.input.radio');
   
   &:before {
     width: get('ui.input.radio');
     height: get('ui.input.radio');
     content: "";
     border-radius: 100%;
   }
   
   &:hover {
     &:before {
        border-color: darken(get('color.grey.light'),2%);
     }
   }
 }
 
 input[type="radio"] {
   &:checked {
     & + label[for] {
       &:before {
         border-width: 8px;
         border-color: get('color.primary.default')
       }
     }
   }
 }
 
 &.small {
   label[for] {
     min-width: get('ui.input.radioSmall');
     height: get('ui.input.radioSmall');
     
     &:before {
       width: get('ui.input.radioSmall');
       height: get('ui.input.radioSmall');
       border-radius: 100%;
     }
   }
   
   input[type="radio"] {
     &:checked {
       & + label[for] {
         &:before {
           border-width: 5px;
         }
       }
     }
   }
 }
}

.upload-file {
 display: flex;
 flex-flow: column nowrap;
 
 header {
   h4 {
     font-size: 16px;
     font-weight: 600;
     text-align: center;
   }
 }
 
 p {
   font-size: 13px;
   text-align: center;
   color: get('color.font.light');
   margin: 12px 0;
 }
 
 input[type="file"] {
   display: none;
   
   & + label {
     display: flex;
     flex-flow: column nowrap;
     align-items: center;
     border: 2px dashed get('color.grey.light');
     padding: get('ui.air.default');
     border-radius: get('ui.border.radius.box');
     cursor: pointer;
   }
 }
}

/* END: Main Framework */

/* START:  Layout Styles */

.main-wrapper {
 display: flex;
 min-height: 100vh;
 flex-flow: column nowrap;
 align-items: stretch;
}

.main-header {
 display: flex;
 width: 100%;
 height: 100px;
 flex-shrink: 0;
 flex-flow: row nowrap;
 align-items: center;
 justify-content: space-between;
 position: fixed;
 z-index: 4;
 background-color: #ffffff82;
 border-bottom: 1px solid #e5e7eb;
 backdrop-filter: blur(10px);
 
  .brand {
    margin-top: 5px;
  }
  
  &.only-brand {
    justify-content: center;
    
    .main-navigation {
      display: none;
    }
  }
  
 @include responsive($min-width: '1480px') {
   padding-left: calc((100% - 1400px)/2);
   padding-right: calc((100% - 1400px)/2);
 }
 
 @include responsive($max-width: '1480px') {
   padding-left: 40px;
   padding-right: 40px;
 }
 
 @include responsive($max-width: '1200px') {
   padding-left: 24px;
   padding-right: 24px;
 }
}

.background-section{
  background-position: top;
  background-repeat: no-repeat;
  background-image: url('/assets/home/background.jpeg');
  background-color: rgb(248 250 252);
}
.main-section {
 @include frame('xlarge','vertical');
 width: 100%;
 height: 100%;
 padding-top: 150px;

 .page-section {
   @include responsive($min-width: '1480px') {
     padding-left: calc((100% - 1400px)/2);
     padding-right: calc((100% - 1400px)/2);
   }

   @include responsive($max-width: '1480px') {
     padding-left: 40px;
     padding-right: 40px;
   }
   
   @include responsive($max-width: '1200px') {
     padding-left: 24px;
     padding-right: 24px;
   }
   
   & > header {
     margin-bottom: get('ui.air.editorial.subsection');
   }
   
   .editorial-content {
     h2 {
       margin-bottom: get('ui.air.medium');
     }
     
     h3 {
       margin-bottom: get('ui.air.medium');
     }
     
     h4 {
       margin-bottom: get('ui.air.medium');
     }
   }
   
   & + .page-section {
     margin-top: get('ui.air.editorial.section');
     
     @include responsive($max-width: get('ui.responsive.mobile.xlarge')) {
       margin-top: get('ui.air.editorial.sectionMobile');
     }
   }
   
   &[class*="bg-"] {
     padding-top: 4em;
     padding-bottom: 4em;
   }
   
   &.hero {
     text-align: center;
   }
 }
}

//Content Sections

.home-hero {
 & > header {
   text-align: center;
 }
 
 .hero-video-samples {
   text-align: center;
    min-height: 590px;
    border-radius: get('ui.border.radius.large');
   
   h4 {
     color: get('color.primary.default');
     font-weight: 500;
     font-size: 1.25em;
   }
   
   h3 {
     line-height: 1.1;
   }
   
   .type-note {
     margin-top: get('ui.air.medium');
     margin-bottom: get('ui.air.medium');
   }
   
   .video-box {
     &.vertical {
       width: 28%;
       margin-right: get('ui.air.mediumLarge');
     }
   }
   
   .try-xound {
     @include frame('xlarge','top');
     @include frame('large','horizontal');
     @include frame('default','bottom');
     @include box('white');
     display: flex;
     flex-flow: column nowrap;
     flex-grow: 2;
     
     form {
       display: flex;
       flex-flow: column nowrap;
       flex: 2;
     }
     
     .upload-file {
       display: flex;
       height: 100%;
       margin-top: get('ui.air.medium');
       margin-bottom: get('ui.air.medium');
       flex: 2;
       
       input[type="file"] {
         & + label {
           display: flex;
           flex-flow: column nowrap;
           justify-content: center;
           height: 100%;
         }
       }
     }
     
     .button-primary {
       width: 100%;
       margin-top: auto;
     }
   }
   
   @include responsive($min-width: '880px') {
     .video-box {
       &.fullwidth {
         display: none;
       }
     }
   }
   
   @include responsive($max-width: '1280px') {
     min-height: 420px;
   }
   
   @include responsive($max-width: '1180px', $min-width: '960px') {
     .video-box {
       &.vertical {
         &:nth-child(3) {
           display: none;
         }
       }
     }
   }
   
   @include responsive($max-width: '960px') {
     min-height: auto;
     flex-wrap: wrap;
     
     .video-box {
       &.vertical {
         min-height: 380px;
         flex-grow: 1;

         &:nth-child(3) {
           margin-right: 0;
         }
       }
     }
     
     .try-xound {
       @include frame('default','top');
       margin-top: get('ui.air.default');
       flex-direction: row;
       align-items: center;
       
       .content {
         margin-right: get('ui.air.default');
         flex-grow: 1;
       }
       
       form {
         max-width: 440px;
         flex-grow: 2;
       }
     }
   }
   
   @include responsive($max-width: '880px') {
     .video-box {
       &.vertical {
        &:nth-child(3) {
          display: none;
        }
       }
     }
   }
   @include responsive($max-width: '500px') {
    .video-box {
      &.vertical {
       &:nth-child(3) {
         display: none;
       }
       &:nth-child(2) {
        display: none;
      }
      }
    }
  }
   
   @include responsive($max-width: get('ui.responsive.mobile.default')) {
     .try-xound {
       flex-direction: column;
       align-items: center;
       
       h3 {
         br {
           display: none;
         }
       }
       
       .content {
         margin-right: 0;
       }
       
       form {
         max-width: 100%;
         width: 100%;
       }
     }
   }
 }
}

.advanced-ai {
 & > .flex-rows {
   .editorial-content {
     padding-right: 6em;
   }
   
   .image {
     min-width: 480px;
     
     .image-frame {
       position: sticky;
       top: 120px;
     }
   }
   
   @include responsive($min-width: '1200px') {
     padding-left: 4em;
     padding-right: 4em;
   }
   
   @include responsive($max-width: '1200px') {
     .editorial-content {
       padding-right: 4em;
       
       .grid {
         grid-template-columns: repeat(1, 1fr);
       }
     }
   }
   
   @include responsive($max-width: get('ui.responsive.mobile.xlarge')) {
     .editorial-content {
       padding-right: 0;
       
       & > header {
         text-align: center;
         padding-bottom: get('ui.air.large');
       }
       
       .grid {
         & > div {
           display: flex;
           flex-flow: column nowrap;
           align-items: center;
         }
       }
     }
     
     .image {
       min-width: 100%;
       margin-top: get('ui.air.large');
     }
   }
 }
}

.three-steps {
 & > header {
   text-align: center;
 }
 
 & > .editorial-content {
   column-gap: 3em;
   
   & > div {
     h2,
     h4 {
       text-align: center;
     }
     
     .image-frame {
       @include frame;
       justify-content: center;
       
       img {
         width: auto;
         height: 190px;
       }
     }
   }
   
   @include responsive($max-width: '1200px') {
     grid-template-columns: repeat(1, 1fr);
     
     & > div {
       display: flex;
       flex-flow: row nowrap;
       align-items: center;
       max-width: 720px;
       margin-left: auto;
       margin-right: auto;
       
       .capital-number {
         font-size: 2.2em;
         text-align: left;
       }
       
       h4 {
         text-align: left;
       }
       
       .image-frame {
         margin-bottom: 0;
         margin-right: get('ui.air.default');
         
         img {
           max-width: 260px;
           height: auto;
         }
       }
     }
   }
   
   @include responsive($max-width: get('ui.responsive.mobile.large')) {
     & > div {
       flex-direction: column;
       max-width: 720px;
       
       .content {
         display: flex;
         flex-flow: column nowrap;
         align-items: center;
       }
     }
   }
 }
}

.use-cases {
 @include responsive($min-width: '1280px') {
   .image-frame {
     width: 100%;
     height: 238px;

     img {
       position: absolute;
       top: 0;
       left: -50%;
       transform: translatex(50%);
     }
   }
 }
}

.pricing {
 & > header {
   text-align: center;
 }
 
 .price-plan {
   @include frame('large');
   text-align: center;
   
   .plan-price {
     display: flex;
     flex-flow: row nowrap;
     align-items: center;
     justify-content: center;
     margin: 2em auto 8px auto;
     font-weight: 800;
     
     .currency {
       font-size: 42px;
       line-height: 56px;
     }
     
     .value {
       font-size: 60px;
       line-height: 56px;
     }
   }
   
   .payment-frequency {
     color: get('color.font.medium');
     font-size: 20px;
   }
   
   .plan-description,
   .plan-details {
     max-width: 210px;
     margin-left: auto;
     margin-right: auto;
   }
   
   .plan-description {
     max-width: 280px;
   }
   
   .plan-details {
     max-width: 210px;
     text-align: left;
     margin-bottom: get('ui.air.large');
   }
   
   .plan-selection {
     margin-top: auto;
   }
 }
}

.media-viewer {
  border-radius: get('ui.border.radius.box');
  background-color: get('color.grey.medium');
  overflow: hidden;
  
  video,
  img {
    display: block;
    width: 100%;
    height: auto;
    
  }
  .enhanced-original-toggle {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px 6px 8px;
    min-height: 32px;
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: rgba(#FFF,0.85);
    border-radius: get('ui.border.radius.box');
    
    label[for] {
      //color: rgba(#FFF,0.75);
    }
    
    input[type="checkbox"] {
      &:checked {
        & + label[for] {
          //color: #FFF;
        }
      }
    }
  }
  
  .menu-tabs {
    display: none;
    position: absolute;
    top: 12px;
    right: 12px;
    
    & > .menu-tab {
      font-size: 15px;
      min-height: 32px;
      background-color: rgba(#000,0.25);
      color: rgba(#FFF,0.75) !important;
      
      i {
        display: none;
      }
      
      &.active {
        color: #FFF !important;
        background-color: rgba(#000,0.5);
        
        i {
          display: block;
          color: #FFF !important;
        }
      }
      
      &:hover {
        color: #FFF !important;
      }
    }
  }
}

.media-tools {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.media-timeline-viewer {
  height: 80px;
  position: relative;
  
  .segment-selection {
    box-shadow: inset 0 0 0 3px get('color.primary.default');
    border-radius: get('ui.border.radius.box');
    height: 55px;
    position: absolute;
    top: 0;
    z-index: 2;
    
    .handle-left,
    .handle-right {
      width: 12px;
      height: calc(100% - 16px);
      background-color: get('color.primary.default');
      position: absolute;
      top: 8px;
      cursor: pointer;
      
      &:before {
        display: block;
        content: "";
        width: 2px;
        height: calc(100% - 20px);
        border-radius: 6px;
        background-color: #FFF;
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -1px;
      }
    }
    
    .handle-left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      left: -10px;
    }
    
    .handle-right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      right: -10px;
    }
  }
  
  .sound-chart {
    height: 55px;
    border-radius: get('ui.border.radius.box');
    background-color: #FFF;
    position: relative;
    overflow: hidden;
  }
}

.try-xound {
  .button-mega {
    @include responsive($min-width: get('ui.responsive.mobile.medium')) {
      width: 416px;
    }
  }
  
  .testimonials {
    margin-top: 8em;
  }
  
  @include responsive($max-width: get('ui.responsive.mobile.large')) {
    .file-upload {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      
      & > .button-mega,
      & > div {
        width: 100%;
        margin-bottom: 12px !important;
        text-align: center;
      }
    }
    
    .list-icons {
      display: block;
      width: 100%;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.app-preview {
  display: flex;
  flex-flow: row nowrap;
  @include responsive($max-width: '800px') {
    flex-flow: column nowrap;
  }
  align-items: stretch;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  
  .app-viewer,
  .app-sidebar {
    @include box('white');
    padding: 18px;
  }

  .app-viewer {
    width: calc(100% - 332px);
    @include responsive($max-width: '800px') {
      width: 100%;
    }
    margin-right: 12px;
    
    & > header {
      padding-bottom: 20px;
      
      .media-file-name {
        font-size: 18px;
        font-weight: 600;
      }
    }
    
    .media-tools {
      margin: 16px 0;
    }
  }
  
  .app-sidebar {
    width: 320px;
    @include responsive($max-width: '800px') {
      width: 100%;
    }
    
    & > header {
      text-align: center;
      padding: 1.5em 0 2em 0;
      
      p {
        font-size: 1.1em;
        color: get('color.font.medium');
      }
    }
  }

  .purchase-options {
    & > .box {
      margin-bottom: get('ui.air.medium');
      padding: 0.75em;
      
      & > header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.75em;
        
        .total-selected-length {
          color: get('color.font.lighter');
        }
        
        h5 {
          font-size: 17px;
        }
      }

      &:has(input:checked) {
        outline: 2px solid get('color.secondary.medium');
      }
    }

    & > footer {
      margin-top: 2em;
    }
 }
  
  .purchase-option {
    @include box('default');
    padding: 0.75em 0.65em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    
    .purchase-price {
      font-weight: 700;
      color: get('color.font.dark');
      font-size: 20px;
    }
    
    .input-radio {
      margin-bottom: 0;
      
      label[for] {
        font-size: 18px;
        color: get('color.font.dark');
        font-weight: 400;
        
        span {
          font-size: 75%;
          color: get('color.font.lighter');
          transform: translatey(1px);
          margin-left: 2px;
        }
      }
      
      label[for] {
        &:before {
          border-radius: 6px;
        }
      }

      input[type="radio"] {
        &:checked {
          & + label[for] {
            &:before {
              border-width: 2px;
              border-color: get('color.primary.default');
              background-color: get('color.primary.default');
            }
            
            &:after {
              font-family: "Font Awesome 6 Pro";
              display: flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              height: 22px;
              content: "\f00c";
              font-weight: 800;
              position: absolute;
              top: 0;
              left: 0;
              color: get('color.accent.first');
              font-size: 14px;
            }
          }
        }
      }
    }
    
    &:hover {
      border-color: get('color.grey.light');
    }
    
    & + .purchase-option {
      margin-top: 8px;
    }
  }
}

.preloading-screen {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 8em;
  text-align: center;

  h3 {
    display: block;
    margin: 80px 0;
    font-weight: 100;
    letter-spacing: -1px;
    text-align: center;
    
    .stripe-logo {
      margin: 0 6px;
      
      img {
        transform: translatey(8px);
      }
    }
  }
  
  video {
    margin: 0 auto;
  }
  
  .box {
    display: inline-flex;
    align-items: center;
    padding: 12px 18px;
    width: 530px;
    margin: 0 auto;
    
    p {
      color: get('color.font.medium');
      font-size: 19px;
      font-weight: 400;
      
      i {
        font-size: 80%;
        color: get('color.font.lighter');
        transform: translatey(-1px);
      }
      
      &.powered-by-stripe {
        display: inline-flex;
        align-items: center;
        font-size: 15px;
        margin-left: 30px;
        
        .stripe-logo {
          height: 18px;
          margin-left: 3px;
          
          img {
            display: block;
            width: auto;
            height: 18px;
            transform: translatey(1px);
          }
        }
      }
    }
  }
}

/* END:  Layout Styles */

/* START:  Pen Specific Styles */

[data-hotspot-section] {
  &:not(.active) {
    display: none;
  }
}

/* END:  Pen Specific Styles */

::part(region) {
  box-shadow: inset 0 0 0 3px #4314FF;
  border-radius: 8px;
}
::part(region-handle-right) {
  border-right-width: 0px !important;
}
::part(region-handle-left) {
  border-left-width: 0px !important;
  width: 12px !important;
  height: calc(100% - 16px) !important;
  background-color: #4314FF;
  position: absolute !important;
  top: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  left: -10px !important;
}
::part(region-handle-right) {
  border-left-width: 0px !important;
  width: 12px !important;
  height: calc(100% - 16px) !important;
  background-color: #4314FF;
  position: absolute !important;
  top: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  right: -10px !important;
}
::part(region-handle-left):before,::part(region-handle-right):before {
  display: block;
  content: "";
  width: 2px;
  height: calc(100% - 20px);
  border-radius: 6px;
  background-color: #FFF;
  position: absolute;
  top: 10px;
  left: 50%;
  margin-left: -1px;
}